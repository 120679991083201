import { useEffect, useState } from "react";
import { theme_color_black, theme_color_white } from "../../const";
import { useSelector } from "react-redux";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import sofa_image from "./../images/sofa_image.png";
import sofa_image_mobile from "./../images/sofa_image_mobile.png";
import { useNavigate } from "react-router-dom";

export const Homepage = ({ homeRef }) => {
  const navigate = useNavigate();
  const startBooking = () => {
    navigate("/thankyou");
  };
  const deviceType = useSelector((state) => state.DeviceType.screenType);
  const languageState = useSelector((state) => state.Language.language);
  const [isMobile, setIsMobile] = useState(deviceType === "mobile");
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    if (deviceType === "wide") {
      setIsMobile(false);
    }
    if (deviceType === "narrow") {
      setIsMobile(false);
    }
    if (deviceType === "mobile") {
      setIsMobile(true);
    }
  }, [deviceType]);

  useEffect(() => {
    if (languageState === "English") {
      import(`../en.json`)
        .then((res) => {
          setTranslations(res.home);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
    if (languageState === "Malay") {
      import(`../bm.json`)
        .then((res) => {
          setTranslations(res.home);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
  }, [languageState]);

  return (
    <Flex
      ref={homeRef}
      flexDir={"column"}
      w={"100%"}
      bgColor={theme_color_white}
      pb={isMobile ? "15%" : "50px"}
      pl={isMobile ? "5%" : "20px"}
      pr={isMobile ? "5%" : "20px"}
    >
      {isMobile && (
        <Flex alignContent={"start"} position={"relative"}>
          <Image src={sofa_image_mobile} position={"top"} />
          <Text
            position="absolute"
            width="90%"
            top="25px"
            left="5%"
            textAlign="center"
            className="font-link-Ibarra"
            fontSize="32px"
            color={"white"}
          >
            {translations.tagline}
          </Text>
        </Flex>
      )}
      <Flex
        flexDir={isMobile ? "column" : "row"}
        w={"100%"}
        pt={isMobile ? "5%" : "20px"}
      >
        {!isMobile && (
          <Flex
            justifyContent={"start"}
            align={isMobile ? "center" : "start"}
            w={isMobile ? "100%" : "50%"}
            p={"5px"}
          >
            <Text
              textAlign={isMobile ? "center" : "start"}
              className="font-link-Anton"
              fontSize={isMobile ? "45px" : "70px"}
            >
              {translations.tagline}
            </Text>
          </Flex>
        )}
        <Flex
          direction="column"
          w={isMobile ? "100%" : "50%"}
          justify="space-between"
        >
          <Text
            className={isMobile ? "font-link-Benne" : "font-link-OpenSans"}
            fontSize={isMobile ? "16px" : "25px"}
            p={"5px"}
            textAlign={"justify"}
          >
            {translations.message}
          </Text>
          <Flex
            alignSelf={isMobile ? "center" : "flex-end"}
            className="font-link-Anton"
            fontSize={isMobile ? "30px" : "44px"}
            bgColor={theme_color_black}
            color={theme_color_white}
            p={"5px"}
            pl={"5%"}
            pr={"5%"}
            mt={isMobile ? "7%" : "25px"}
            textAlign={"center"}
            borderRadius={"20px"}
            boxShadow={"7px 7px 5px rgba(0, 0, 0, 0.5)"}
            cursor={"pointer"}
            onClick={() => {
              startBooking();
            }}
          >
            {translations.button}
          </Flex>
        </Flex>
      </Flex>
      {!isMobile && (
        <Flex alignContent={"start"} p={"40px"}>
          <Image src={sofa_image} position={"bottom"} />
        </Flex>
      )}
    </Flex>
  );
};
