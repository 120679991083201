import { Flex, Image } from "@chakra-ui/react";
import { Homepage } from "./screens/Homepage";
import { Features } from "./screens/Features";
import { ImageSlide } from "./screens/ImageSlide";
import { Review } from "./screens/Review";
import { Location } from "./screens/Location";
import { PlacesAround } from "./screens/PlacesAround";
import { FAQ } from "./screens/FAQ";
import { AboutUs } from ".//screens/AboutUs";
import { ContactUs } from "./screens/ContactUs";
import wood_border from "./images/wood_border.svg";

const Border = () => {
  return (
    <Flex>
      <Image src={wood_border} />
    </Flex>
  );
};

export const LandingPage = ({
  featureRef,
  reviewRef,
  locationRef,
  contactUsRef,
  homeRef,
}) => {
  return (
    <Flex w={"100%"} flexDir={"column"} bgColor="red">
      <Homepage homeRef={homeRef} />
      <Features featureRef={featureRef} />
      <ImageSlide />
      <Review reviewRef={reviewRef} />
      <Location locationRef={locationRef} />
      <PlacesAround />
      <FAQ />
      <AboutUs />
      <ContactUs contactUsRef={contactUsRef} />
    </Flex>
  );
};
