import { useEffect, useRef, useState } from "react";
import { theme_color_black, theme_color_white } from "../../const";
import { useSelector } from "react-redux";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import superhost from "./../images/superhost.svg";
import star from "./../images/star.svg";
import { reviewList } from "./../../const";
import { Swiper, SwiperSlide } from "swiper/react";
import CountUp from "react-countup";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";
import { useIntersectionObserver } from "../intersectionObserver";
import { setReviewVisible } from "../redux/reviewVisibleSlice";
import { useAppDispatch } from "../../store";

export const Review = ({ reviewRef }) => {
  const dispatch = useAppDispatch();
  const deviceType = useSelector((state) => state.DeviceType.screenType);
  const languageState = useSelector((state) => state.Language.language);
  const [isMobile, setIsMobile] = useState(deviceType === "mobile");
  const [translations, setTranslations] = useState({});
  const [startCount, setStartCount] = useState(false);
  const counterRef = useRef(null);
  let reviewLength = reviewList.length;
  const frameRef = useRef();
  let isVisible = useIntersectionObserver(frameRef, { threshold: 0.1 });
  dispatch(setReviewVisible(isVisible));

  useEffect(() => {
    if (deviceType === "wide") {
      setIsMobile(false);
    }
    if (deviceType === "narrow") {
      setIsMobile(false);
    }
    if (deviceType === "mobile") {
      setIsMobile(true);
    }
  }, [deviceType]);

  useEffect(() => {
    if (languageState === "English") {
      import(`../en.json`)
        .then((res) => {
          setTranslations(res.review);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
    if (languageState === "Malay") {
      import(`../bm.json`)
        .then((res) => {
          setTranslations(res.review);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
  }, [languageState]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartCount(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  useEffect(() => {
    dispatch(setReviewVisible(isVisible));
  }, [isVisible]);

  const ReviewBox = ({ image, rating, review, guestName, colorMode }) => {
    return (
      <Flex
        ref={frameRef}
        flexDir={"column"}
        justifyContent={"space-around"}
        w={"99%"}
        h={"100%"}
        bgColor={colorMode === "dark" ? "#646464" : "#F1F1F1"}
        borderRadius={"30px"}
        align={"center"}
        paddingY={isMobile ? "20px" : "30px"}
        paddingX={"35px"}
      >
        <Flex
          flexDir={"column"}
          justifyContent={"center"}
          w={"100%"}
          align={"center"}
        >
          <Box
            boxSize={isMobile ? "110px" : "150px"}
            borderRadius="full"
            overflow="hidden"
            border="2px"
            borderColor="gray.200"
            alignContent={"center"}
          >
            <Image
              src={image}
              objectFit="cover"
              width="100%"
              height="100%"
              alignSelf={"center"}
            />
          </Box>

          <Flex
            flexDir={"row"}
            justifyContent={"center"}
            w={"100%"}
            pt={"20px"}
          >
            {Array.from({ length: rating }).map((_, index) => {
              return (
                <Image
                  src={star}
                  objectFit="cover"
                  width={isMobile ? "8%" : "10%"}
                  alignSelf={"center"}
                  ml={"5px"}
                  mr={"5px"}
                />
              );
            })}
          </Flex>
        </Flex>
        <Flex justify={"center"}>
          <Text
            textAlign={"center"}
            align={"center"}
            className="font-link-Open-Sans"
            fontSize={isMobile ? "15px" : "18px"}
            color={colorMode === "dark" ? theme_color_white : theme_color_black}
            pt={"10px"}
            pb={isMobile ? "15px" : "30px"}
          >
            {review}
          </Text>
        </Flex>
        <Flex justify={"center"} >
          <Text
            textAlign={"center"}
            align={"center"}
            className="font-link-Righteous"
            fontSize={isMobile ? "15px" : "18px"}
            color={colorMode === "dark" ? theme_color_white : theme_color_black}
            pt={"10px"}
            pb={isMobile ? "0px" : "30px"}
          >
            {`- ${guestName}`}
          </Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex
      ref={reviewRef}
      flexDir={"column"}
      w={"100%"}
      bgColor={theme_color_white}
      p={isMobile ? "5%" : "50px"}
      pt={isMobile ? "10%" : "50px"}
    >
      <Flex
        justifyContent={isMobile ? "center" : "start"}
        w={"100%"}
        align={"center"}
        pb={"5%"}
      >
        <Text
          textAlign={isMobile ? "center" : "start"}
          className="font-link-Anton"
          fontSize={isMobile ? "32px" : "70px"}
          color={theme_color_black}
        >
          {translations.tagline1_1}
          {isMobile? <br />: " "}
          {translations.tagline1_2}
        </Text>
      </Flex>
      <Flex bgColor={"white"}>
        <Swiper
          borderRadius={"30%"}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={isMobile ? "1" : "4"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          autoplay={{
            delay: 3000, // 3 seconds
            disableOnInteraction: isMobile ? true : false,
          }}
          modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
          className="swiper_container"
        >
          {Array.from({ length: reviewLength }, (_, index) => index).map(
            (index) => {
              return (
                <SwiperSlide>
                  <ReviewBox
                    image={reviewList[index].image}
                    rating={reviewList[index].rating}
                    review={reviewList[index].review}
                    guestName={reviewList[index].guestName}
                    colorMode={index % 2 === 0 ? "light" : "dark"}
                  />
                </SwiperSlide>
              );
            }
          )}
        </Swiper>
      </Flex>
      <Flex
        flexDir={isMobile ? "column" : "row"}
        pt={"20%"}
        justifyContent={"space-between"}
      >
        <Flex flexDir={"column"} justifyContent={"center"}>
          <Flex justifyContent={isMobile ? "center" : "start"} align={"center"}>
            <Text
              textAlign={isMobile ? "center" : "start"}
              className="font-link-Benne"
              fontSize={isMobile ? "32px" : "63px"}
              color={theme_color_black}
            >
              {translations.tagline2}
            </Text>
          </Flex>
          <Flex justifyContent={isMobile ? "center" : "start"} align={"center"}>
            <Text
              textAlign={isMobile ? "center" : "start"}
              className="font-link-Benne"
              fontSize={isMobile ? "20px" : "28px"}
              color={"#AF6534"}
            >
              {translations.tagline3}
            </Text>
          </Flex>
        </Flex>
        {(isMobile ?
          <Flex
            flexDir="column"
            w={"100%"}
            justifyContent={"space-evenly"}
            align={"center"}
            paddingX={"5%"}
          >
            <Flex
              flexDir="row"
              justifyContent={"space-evenly"}
              width="100%"
            >
              <Flex width="50%" justifyContent={"center"}>
                <Flex
                  flexDir={"column"}
                  justifyContent={"center"}
                  align={"center"}
                  p={"10px"}
                >
                  <Text
                    align={"center"}
                    className="font-link-Ibarra"
                    fontSize="35px"
                    color={theme_color_black}
                  >
                    {startCount && (
                      <CountUp start={0} end={translations.quantity1} duration={3} />
                    )}
                  </Text>
                  <Text
                    align={"center"}
                    className="font-link-Benne"
                    fontSize="18px"
                    color={theme_color_black}
                  >
                    {translations.feature1}
                  </Text>
                </Flex>
              </Flex>
              <Flex width="50%" justifyContent={"center"}>
                <Flex
                  flexDir={"column"}
                  justifyContent={"center"}
                  align={"center"}
                  p={"10px"}
                >
                  <Text
                    align={"center"}
                    className="font-link-Ibarra"
                    fontSize="35px"
                    color={theme_color_black}
                  >
                    {startCount && (
                      <CountUp start={0} end={translations.quantity2} duration={3} />
                    )}
                  </Text>
                  <Text
                    align={"center"}
                    className="font-link-Benne"
                    fontSize="18px"
                    color={theme_color_black}
                  >
                    {translations.feature2}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              flexDir="row"
              justifyContent={"space-evenly"}
              width="100%"
            >
              <Flex width="50%" justifyContent={"center"}>
                <Flex
                  flexDir={"column"}
                  justifyContent={"start"}
                  align={"center"}
                  p={"10px"}
                >
                  <Text
                    align={"center"}
                    className="font-link-Ibarra"
                    fontSize="35px"
                    color={theme_color_black}
                  >
                    {startCount && (
                      <CountUp start={0} end={translations.quantity3} duration={3} />
                    )}
                  </Text>
                  <Text
                    align={"center"}
                    className="font-link-Benne"
                    fontSize="18px"
                    color={theme_color_black}
                  >
                    {translations.feature3}
                  </Text>
                </Flex>
              </Flex>
              <Flex width="50%" justifyContent={"center"}>
                <Flex
                  flexDir={"column"}
                  justifyContent={"center"}
                  align={"center"}
                  p={"10px"}
                >
                  <Text
                    align={"center"}
                    className="font-link-Ibarra"
                    fontSize="35px"
                    color={theme_color_black}
                  >
                    {startCount && (
                      <CountUp
                        start={0}
                        end={translations.quantity4}
                        duration={5}
                        suffix="%"
                      />
                    )}
                  </Text>
                  <Text
                    align={"center"}
                    className="font-link-Benne"
                    fontSize="18px"
                    color={theme_color_black}
                  >
                    {translations.feature4}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          : <Flex flexDir={"row"} justifyContent={"space-around"} ref={counterRef}>
            <Flex
              flexDir={"column"}
              justifyContent={"center"}
              align={"center"}
              p={"10px"}
            >
              <Text
                align={"center"}
                className="font-link-Ibarra"
                fontSize={isMobile ? "35px" : "50px"}
                color={theme_color_black}
              >
                {startCount && (
                  <CountUp start={0} end={translations.quantity1} duration={3} />
                )}
              </Text>
              <Text
                align={"center"}
                className="font-link-Benne"
                fontSize={isMobile ? "18px" : "23px"}
                color={theme_color_black}
              >
                {translations.feature1}
              </Text>
            </Flex>
            <Flex
              flexDir={"column"}
              justifyContent={"center"}
              align={"center"}
              p={"10px"}
            >
              <Text
                align={"center"}
                className="font-link-Ibarra"
                fontSize={isMobile ? "35px" : "50px"}
                color={theme_color_black}
              >
                {startCount && (
                  <CountUp start={0} end={translations.quantity2} duration={3} />
                )}
              </Text>
              <Text
                align={"center"}
                className="font-link-Benne"
                fontSize={isMobile ? "18px" : "23px"}
                color={theme_color_black}
              >
                {translations.feature2}
              </Text>
            </Flex>
            <Flex
              flexDir={"column"}
              justifyContent={"center"}
              align={"center"}
              p={"10px"}
            >
              <Text
                align={"center"}
                className="font-link-Ibarra"
                fontSize={isMobile ? "35px" : "50px"}
                color={theme_color_black}
              >
                {startCount && (
                  <CountUp start={0} end={translations.quantity3} duration={3} />
                )}
              </Text>
              <Text
                align={"center"}
                className="font-link-Benne"
                fontSize={isMobile ? "18px" : "23px"}
                color={theme_color_black}
              >
                {translations.feature3}
              </Text>
            </Flex>
            <Flex
              flexDir={"column"}
              justifyContent={"center"}
              align={"center"}
              p={"10px"}
            >
              <Text
                align={"center"}
                className="font-link-Ibarra"
                fontSize={isMobile ? "35px" : "50px"}
                color={theme_color_black}
              >
                {startCount && (
                  <CountUp
                    start={0}
                    end={translations.quantity4}
                    duration={5}
                    suffix="%"
                  />
                )}
              </Text>
              <Text
                align={"center"}
                className="font-link-Benne"
                fontSize={isMobile ? "18px" : "23px"}
                color={theme_color_black}
              >
                {translations.feature4}
              </Text>
            </Flex>
          </Flex>)}
      </Flex>
    </Flex>
  );
};
