import { useEffect, useState } from "react";
import {
  theme_color_black,
  theme_color_blue,
  theme_color_white,
} from "../../const";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import { IoIosArrowDown } from "react-icons/io";

export const FAQ = () => {
  const deviceType = useSelector((state) => state.DeviceType.screenType);
  const languageState = useSelector((state) => state.Language.language);
  const [isMobile, setIsMobile] = useState(deviceType === "mobile");
  const [translations, setTranslations] = useState({});
  const [faqList, setFaqList] = useState({});

  useEffect(() => {
    if (deviceType === "wide") {
      setIsMobile(false);
    }
    if (deviceType === "narrow") {
      setIsMobile(false);
    }
    if (deviceType === "mobile") {
      setIsMobile(true);
    }
  }, [deviceType]);

  useEffect(() => {
    if (languageState === "English") {
      import(`../en.json`)
        .then((res) => {
          setTranslations(res.faq);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
      import(`../faqItems_en.json`)
        .then((res) => {
          setFaqList(res.faqList);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
    if (languageState === "Malay") {
      import(`../bm.json`)
        .then((res) => {
          setTranslations(res.faq);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
      import(`../faqItems_bm.json`)
        .then((res) => {
          setFaqList(res.faqList);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
  }, [languageState]);

  return (
    <Flex
      flexDir={"column"}
      justifyContent={"space-around"}
      w={"100%"}
      bgColor={theme_color_white}
      p={isMobile ? "5%" :"50px"}
    >
      <Flex
        justifyContent={isMobile ? "center" : "start"}
        w={"100%"}
        align={"center"}
      >
        <Text
          textAlign={isMobile ? "center" : "start"}
          className="font-link-Anton"
          fontSize={isMobile ? "24px" : "60px"}
          color={"#AF6534"}
        >
          {translations.tagline1}
        </Text>
      </Flex>
      <Flex
        justifyContent={isMobile ? "center" : "start"}
        w={"100%"}
        align={"center"}
      >
        <Text
          textAlign={isMobile ? "center" : "start"}
          className="font-link-Anton"
          fontSize={isMobile ? "32px" : "44px"}
          color={theme_color_black}
        >
          {translations.tagline2}
        </Text>
      </Flex>
      <Flex h={isMobile ? "20px" : "50px"}></Flex>
      <Flex
        w={"100%"}
        pl={isMobile ? "0px" : "50px"}
        pr={isMobile ? "0px" : "50px"}
      >
        <Accordion w={"100%"} bgColor={"white"} allowMultiple>
          {faqList.length > 0 &&
            faqList.map((item, index) => (
              <AccordionItem w={"100%"} key={index} color={theme_color_blue}>
                <AccordionButton
                  color={"white"}
                  _hover={{ backgroundColor: "#AF653470" }}
                  bgColor={theme_color_white}
                >
                  <Flex
                    className="font-link-Karma"
                    w={"100%"}
                    fontSize={isMobile ? "18px" : "20px"}
                    fontWeight={400}
                    textAlign={"left"}
                    p={"10px"}
                    color={theme_color_black}
                  >
                    {item.question}
                  </Flex>
                  <IoIosArrowDown color={"#AF653470"} size={isMobile ? "30px" : "40px"} />
                </AccordionButton>
                <AccordionPanel pb={0} bgColor={"#AF653420"}>
                  <Box
                    className="font-link-Karma"
                    flex="1"
                    textAlign="left"
                    fontSize={"15px"}
                    fontWeight={400}
                    p={"15px"}
                    color={theme_color_black}
                  >
                    {item.answer}
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            ))}
        </Accordion>
      </Flex>
    </Flex>
  );
};
