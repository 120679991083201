import { useEffect, useState } from "react";
import '../../App.css';
import { theme_color_black, theme_color_white } from "../../const";
import { useSelector } from "react-redux";
import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { IconButton, Circle, Flex, Image,Text } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import balconyView1 from "./../images/airbnbImages/balcony1_view.png";
import balconyView2 from "./../images/airbnbImages/balcony2_view.png";
import balconyDoorView from "./../images/airbnbImages/balcony_door_view.png";
import bathroom from "./../images/airbnbImages/bathroom.jpg";
import diningTable from "./../images/airbnbImages/dining_table.png";
import hall from "./../images/airbnbImages/hall.png";
import playingPS4 from "./../images/airbnbImages/ps4.png";
import kitchen from "./../images/airbnbImages/kitchen.png";
import pool from "./../images/airbnbImages/pool.png";
import games from "./../images/airbnbImages/games.png";
import bed from "./../images/airbnbImages/bed.png";
import prevIcon from "./../images/prev.svg";
import nextIcon from "./../images/next.svg";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

export const ImageSlide = () => {
  const imageArray = [
    [hall, diningTable, balconyView2, playingPS4],
    [kitchen, pool, games, bed],
    [hall, diningTable, balconyView2, playingPS4],
    [kitchen, pool, games, bed],
  ];
  const airbnbImages = [hall, pool, playingPS4, balconyView1, diningTable, kitchen, balconyView2, games, bed, balconyDoorView, bathroom]
  const deviceType = useSelector((state) => state.DeviceType.screenType);
  const languageState = useSelector((state) => state.Language.language);
  const [isMobile, setIsMobile] = useState(deviceType === "mobile");
  const [translations, setTranslations] = useState({});
  const [image1, setImage1] = useState(imageArray[0][0]);
  const [image2, setImage2] = useState(imageArray[0][1]);
  const [image3, setImage3] = useState(imageArray[0][2]);
  const [image4, setImage4] = useState(imageArray[0][3]);
  let [slideDotStatus, setSlideDotStatus] = useState(1);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const slideNo = 4;

  useEffect(() => {
    if (deviceType === "wide") {
      setIsMobile(false);
    }
    if (deviceType === "narrow") {
      setIsMobile(false);
    }
    if (deviceType === "mobile") {
      setIsMobile(true);
    }
  }, [deviceType]);

  useEffect(() => {
    if (languageState === "English") {
      import(`../en.json`)
        .then((res) => {
          setTranslations(res.imageSlide);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
    if (languageState === "Malay") {
      import(`../bm.json`)
        .then((res) => {
          setTranslations(res.imageSlide);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
  }, [languageState]);

  function updateImageSlider(slideDotStatus) {
    setImage1(imageArray[slideDotStatus - 1][0]);
    setImage2(imageArray[slideDotStatus - 1][1]);
    setImage3(imageArray[slideDotStatus - 1][2]);
    setImage4(imageArray[slideDotStatus - 1][3]);
  }

  function next() {
    if (slideDotStatus < slideNo) {
      updateImageSlider(slideDotStatus + 1);
      setSlideDotStatus(slideDotStatus + 1);
    } else {
      updateImageSlider(1);
      setSlideDotStatus(1);
    }
  }

  function prev() {
    if (slideDotStatus > 1) {
      updateImageSlider(slideDotStatus - 1);
      setSlideDotStatus(slideDotStatus - 1);
    } else {
      updateImageSlider(slideNo);
      setSlideDotStatus(slideNo);
    }
  }

  const SliderDots = ({ slideDotStatus }) => {
    const slidesArray = Array.from({ length: slideNo }, (_, index) => index);
    return (
      <Flex w={`${slideNo}%`} flexDir={"row"} justifyContent={"space-around"}>
        {slidesArray.map((index) => {
          let color =
            index + 1 === slideDotStatus ? theme_color_black : "lightgrey";
          return <Circle bgColor={color} w={"10px"} h={"10px"} />;
        })}
      </Flex>
    );
  };

  const ImageBox = ({ image1, image2, onImageClick, index1, index2 }) => {
    return (
      <Flex flexDir={"row"} w={"100%"} justifyContent={"space-around"} h={"100%"}>
        <Flex alignContent={"center"} w={image2 ? "49%" : "100%"} onClick={() => onImageClick(index1)}>
          <Image
            src={image1}
            w={"100%"}
            fit={"cover"}
            borderRadius={"15px"}
          />
        </Flex>
        {image2 && (
          <Flex alignContent={"center"} w={"49%"} onClick={() => onImageClick(index2)}>
            <Image
              src={image2}
              w={"100%"}
              fit={"cover"}
              borderRadius={"15px"}
            />
          </Flex>
        )}
      </Flex>
    );
  };

  const handleImageClick = (index) => {
    setActiveIndex(index);
    setIsFullScreen(true);
  };

  return (
    <Flex
      flexDir={"column"}
      w={"100%"}
      h={"100%"}
      bgColor={theme_color_white}
      p={isMobile ? "5%" : "50px"}
      pb={isMobile ? "10%" : null}
    >
      <Flex
        justifyContent={isMobile ? "center" : "start"}
        w={"100%"}
        align={"center"}
        pb={"5%"}
      >
        <Text
          textAlign={isMobile ? "center" : "start"}
          className="font-link-Anton"
          fontSize={isMobile ? "32px" : "70px"}
          width={"90%"}
          color={theme_color_black}
        >
          {translations.tagline1}
        </Text>
      </Flex>
      {isMobile ?
        <Flex flexDir="column" align="center">
          <Flex w="100%" h={"100%"}>
            <Swiper
              borderRadius={"30%"}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              slidesPerView={isMobile ? "1" : "4"}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2.5,
              }}
              autoplay={{
                delay: 3000, // 3 seconds
                disableOnInteraction: isMobile ? true : false,
              }}
              pagination={{
                clickable: true,
                type: "bullets",
              }}
              modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
              className="swiper_container"
            >
              {airbnbImages.map((image, index) => {
                if ((index + 1) % 3 === 0) {
                  return (
                    <SwiperSlide key={index}>
                      <ImageBox image1={image} onImageClick={() => handleImageClick(index)} />
                    </SwiperSlide>
                  );
                } else if (index % 3 === 0 && index + 1 < airbnbImages.length) {
                  return (
                    <SwiperSlide key={index}>
                      <ImageBox
                        image1={airbnbImages[index]}
                        image2={airbnbImages[index + 1]}
                        index1={index}
                        index2={index + 1}
                        onImageClick={handleImageClick}
                      />
                    </SwiperSlide>
                  );
                }
              })}
            </Swiper>
          </Flex>
          {isFullScreen && (
            <Flex className="fullscreen-swiper" position="fixed" top="0" left="0" width="100%" height="100%" zIndex="1000" bg="rgba(0, 0, 0, 1)">
              <Swiper
                initialSlide={activeIndex}
                slidesPerView={1}
                loop={true}
                navigation
                pagination={{ clickable: true }}
                onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
              >
                {airbnbImages.map((image, index) => (
                  <SwiperSlide key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Image src={image} maxW="100%" maxH="100%" fit="cover" />
                
                  </SwiperSlide>
                ))}
              </Swiper>
              <Flex position="absolute" top="10px" left="0" right="0" justifyContent="center" alignItems="center" zIndex="1001">
                <IconButton
                  icon={<CloseIcon />}
                  position="absolute"
                  top="10px"
                  right="10px"
                  zIndex="1001"
                  borderRadius={"full"}
                  color="black"
                  bg="white"
                  onClick={() => setIsFullScreen(false)}
                  aria-label="Close"
                />
                <Text
                  className="font-link-Anton"
                  fontSize={"20px"}
                  color="white"
                  alignItems={"center"}
                  top="15px"
                  position="absolute"
                >
                  {activeIndex + 1}/{airbnbImages.length}
                </Text>
              </Flex>
            </Flex>
          )}
        </Flex>


        //   <Flex bgColor={"white"}>
        //     {/* <Swiper
        //       borderRadius={"30%"}
        //       effect={"coverflow"}
        //       grabCursor={true}
        //       centeredSlides={true}
        //       loop={true}
        //       slidesPerView={isMobile ? "1" : "4"}
        //       coverflowEffect={{
        //         rotate: 0,
        //         stretch: 0,
        //         depth: 100,
        //         modifier: 2.5,
        //       }}
        //       pagination={{
        //         clickable: true,
        //         type: "bullets",
        //       }}
        //       autoplay={{
        //         delay: 3000, // 3 seconds
        //         disableOnInteraction: isMobile ? true : false,
        //       }}
        //       modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        //       className="swiper_container"
        //     >
        //       {airbnbImages.map((image, index) => {
        //         if ((index + 1) % 3 === 0) {
        //           return (
        //             <SwiperSlide key={index}>
        //               <ImageBox image1={image} />
        //             </SwiperSlide>
        //           );
        //         } else if (index % 3 === 0 && index + 1 < airbnbImages.length) {
        //           return (
        //             <SwiperSlide key={index}>
        //               <ImageBox image1={airbnbImages[index]} image2={airbnbImages[index + 1]} />
        //             </SwiperSlide>
        //           );
        //         }
        //       })}
        //     </Swiper> */}
        //      <Swiper
        //   borderRadius={"30%"}
        //   effect={"coverflow"}
        //   grabCursor={true}
        //   centeredSlides={true}
        //   loop={true}
        //   slidesPerView={isMobile ? "1" : "4"}
        //   coverflowEffect={{
        //     rotate: 0,
        //     stretch: 0,
        //     depth: 100,
        //     modifier: 2.5,
        //   }}
        //   pagination={{
        //     clickable: true,
        //     type: "bullets",
        //   }}
        //   autoplay={{
        //     delay: 3000, // 3 seconds
        //     disableOnInteraction: isMobile ? true : false,
        //   }}
        //   modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        //   className="swiper_container"
        // >
        //   {airbnbImages.map((image, index) => {
        //     if ((index + 1) % 3 === 0) {
        //       return (
        //         <SwiperSlide key={index}>
        //           <ImageBox image1={image} onClick={() => handleImageClick(index)} />
        //         </SwiperSlide>
        //       );
        //     } else if (index % 3 === 0 && index + 1 < airbnbImages.length) {
        //       return (
        //         <SwiperSlide key={index}>
        //           <ImageBox image1={airbnbImages[index]} image2={airbnbImages[index + 1]} onClick={() => handleImageClick(index)} />
        //         </SwiperSlide>
        //       );
        //     }
        //   })}
        // </Swiper>
        //   </Flex>
        : <Flex
          flexDir={"row"}
          w={"100%"}
          justifyContent={"space-around"}
          h={"75%"}
          pb={"30px"}
        >
          <Flex alignContent={"center"} w={"48%"}>
            <Image
              src={image1}
              w={"100%"}
              fit={"cover"}
              borderRadius={"15px"}
              boxShadow={"5px 5px 3px rgba(0, 0, 0, 0.2)"}
            />
          </Flex>
          <Flex
            flexDir={"column"}
            w={"48%"}
            h={"100%"}
            alignSelf={"center"}
            justifyContent={"space-around"}
          >
            <Flex
              flexDir={"row"}
              justifyContent={"space-around"}
              w={"100%"}
              h={"47%"}
            >
              <Image
                src={image2}
                w={"48%"}
                fit={"cover"}
                borderRadius={"15px"}
                boxShadow={"5px 5px 3px rgba(0, 0, 0, 0.2)"}
              />
              <Image
                src={image3}
                w={"48%"}
                fit={"cover"}
                borderRadius={"15px"}
                boxShadow={"5px 5px 3px rgba(0, 0, 0, 0.2)"}
              />
            </Flex>
            <Image
              src={image4}
              w={"100%"}
              h={"47%"}
              fit={"cover"}
              borderRadius={"15px"}
              boxShadow={"5px 5px 3px rgba(0, 0, 0, 0.2)"}
            />
          </Flex>
        </Flex>}
      {isMobile ? null : <Flex
        flexDir={"row"}
        h={"10%"}
        w={"100%"}
        justifyContent={"space-between"}
      >
        <Flex
          flexDir={"column"}
          justifyContent={"center"}
          cursor={"pointer"}
          onClick={() => {
            prev();
          }}
        >
          <Flex justify={"center"}>
            <Image src={prevIcon} align={"center"} w={"50%"} />
          </Flex>
          <Flex justify={"center"}>
            <Text
              textAlign={"center"}
              align={"center"}
              className="font-link-Antonio"
              fontSize={isMobile ? "18px" : "25px"}
              color={theme_color_black}
              pt={"5px"}
              pb={"5px"}
            >
              {translations.button1}
            </Text>
          </Flex>
        </Flex>
        <SliderDots slideDotStatus={slideDotStatus} />
        <Flex
          flexDir={"column"}
          justifyContent={"center"}
          cursor={"pointer"}
          onClick={() => {
            next();
          }}
        >
          <Flex justify={"center"}>
            <Image src={nextIcon} align={"center"} w={"70%"} />
          </Flex>
          <Flex justify={"center"}>
            <Text
              textAlign={"center"}
              align={"center"}
              className="font-link-Antonio"
              fontSize={isMobile ? "18px" : "25px"}
              color={theme_color_black}
              pt={"5px"}
              pb={"5px"}
            >
              {translations.button2}
            </Text>
          </Flex>
        </Flex>
      </Flex>}
    </Flex>
  );
};
