import { createSlice } from "@reduxjs/toolkit";

const initialState = { isVisible: false };

const reviewVisible = createSlice({
  name: "reviewVisible",
  initialState,
  reducers: {
    setReviewVisible: (state, action) => {
      state.isVisible = action.payload;
    },
  },
});
const reviewVisibleReducer = reviewVisible.reducer;

export const { setReviewVisible } = reviewVisible.actions;

export default reviewVisibleReducer;
