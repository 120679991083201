import { useEffect, useState } from "react";
import { theme_color_black, theme_color_white } from "../../const";
import { useSelector } from "react-redux";
import { Flex, Image, Text } from "@chakra-ui/react";
import calendar_pic from "./../images/calendar_icon.svg";
import whatsapp_pic from "./../images/whatsapp_icon.svg";
import phone_pic from "./../images/phone_icon.svg";
import mail_pic from "./../images/mail_icon.svg";
import { TopNavBar } from "../topNavBar/topNavBar";
import logoGif from "./../images/logoGif.gif";
import { useNavigate } from "react-router-dom";

export const ThankYou = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const deviceType = useSelector((state) => state.DeviceType.screenType);
  const languageState = useSelector((state) => state.Language.language);
  const [isMobile, setIsMobile] = useState(deviceType === "mobile");
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    if (deviceType === "wide") {
      setIsMobile(false);
    }
    if (deviceType === "narrow") {
      setIsMobile(false);
    }
    if (deviceType === "mobile") {
      setIsMobile(true);
    }
  }, [deviceType]);

  useEffect(() => {
    if (languageState === "English") {
      import(`../en.json`)
        .then((res) => {
          setTranslations(res.contactUs);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
    if (languageState === "Malay") {
      import(`../bm.json`)
        .then((res) => {
          setTranslations(res.contactUs);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
  }, [languageState]);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.open(
        "https://www.airbnb.com/h/rileklu",
        "_blank",
        "noopener,noreferrer"
      );
      //   goBack();
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Flex
      flexDir={"column"}
      justifyContent={"space-between"}
      w={"100%"}
      bgColor={theme_color_white}
      p={isMobile ? "5%" : "50px"}
      pt={isMobile ? "15%" : "80px"}
    >
      <Flex
        flexDir={isMobile ? "column" : "row"}
        justifyContent={"space-between"}
      >
        <Image
          w={"200px"}
          h={"60px"}
          src={logoGif}
          cursor={"pointer"}
          alignSelf={isMobile ? "center" : "start"}
          onClick={(e) => {
            e.preventDefault();
          }}
        />
        {!isMobile && (
          <Flex
            bgColor={"#AF6534"}
            borderRadius={"15px"}
            flexDir={"row"}
            alignSelf={isMobile ? "center" : "end"}
            justifyContent={"space-around"}
            p={"10px"}
            w={isMobile ? "43%" : "15%"}
            cursor={"pointer"}
            onClick={goBack}
          >
            <Text
              textAlign={"center"}
              className="font-link-Anton"
              fontSize={"34px"}
              color={theme_color_white}
            >
              {"GO BACK"}
            </Text>
          </Flex>
        )}
      </Flex>

      <Flex flexDir={"column"} h={"50%"}>
        <Flex
          justifyContent={isMobile ? "center" : "start"}
          align={"center"}
          w={"100%"}
          p={isMobile ? "2%" : "10px"}
          pt={isMobile ? "10%" : "50px"}
        >
          <Text
            textAlign={isMobile ? "center" : "start"}
            className="font-link-Anton"
            fontSize={isMobile ? "26px" : "60px"}
            color={theme_color_black}
          >
            {"Thank You For Your Interest"}
          </Text>
        </Flex>
        <Flex
          justifyContent={isMobile ? "center" : "start"}
          w={"100%"}
          align={"center"}
        >
          <Text
            textAlign={isMobile ? "center" : "start"}
            className="font-link-Anton"
            fontSize={isMobile ? "26px" : "44px"}
            color={"#AF6534"}
          >
            {"Your booking site should be accessible in a few seconds"}
          </Text>
        </Flex>
      </Flex>

      <Flex flexDir={"column"} justifyContent={"space-between"} h={"50%"}>
        <Flex
          bgColor={theme_color_black}
          borderRadius={"15px"}
          flexDir={"row"}
          alignSelf={"start"}
          justifyContent={"space-around"}
          p={"10px"}
          mt={"50px"}
          w={isMobile ? "93%" : "60%"}
          cursor={"pointer"}
        >
          <a
            href="whatsapp://send?phone=60183531696&text=Hello%2C%20I%20am%20interested%20in%20your%20services"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <Image
              src={whatsapp_pic}
              align={"center"}
              width={isMobile ? "35px" : null}
            />
            <Text
              textAlign={"center"}
              className="font-link-Anton"
              fontSize={isMobile ? "23px" : "44px"}
              color={theme_color_white}
            >
              {translations.button2}
            </Text>
          </a>
        </Flex>
        <Flex
          flexDir={isMobile ? "column" : "row"}
          justifyContent={"start"}
          paddingY={isMobile ? "15px" : "50px"}
        >
          <Flex
            flexDir={isMobile ? "row" : "column"}
            justifyContent={isMobile ? "center" : "start"}
            w={isMobile ? "100%" : "50%"}
            alignItems={"center"}
          >
            <Image
              src={phone_pic}
              align={"center"}
              width={isMobile ? "10px" : null}
              mr={isMobile ? "5%" : null}
            />
            <Text
              align={"center"}
              className="font-link-ArchivoNarrow"
              fontSize={isMobile ? "15px" : "30px"}
              color={theme_color_black}
            >
              +60136180059 | +60183531696
            </Text>
          </Flex>
          <Flex
            flexDir={isMobile ? "row" : "column"}
            justifyContent={isMobile ? "center" : "start"}
            w={isMobile ? "100%" : "50%"}
            alignItems={"center"}
            pt={isMobile ? "20px" : "0px"}
          >
            <Image
              src={mail_pic}
              align={"center"}
              width={isMobile ? "10px" : null}
              mr={isMobile ? "5%" : null}
            />
            <Text
              align={"center"}
              className="font-link-ArchivoNarrow"
              fontSize={isMobile ? "15px" : "30px"}
              color={theme_color_black}
            >
              secret.oasis.co@gmail.com
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
