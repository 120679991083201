import { createSlice } from "@reduxjs/toolkit";

const initialState = { screenType: "wide" };

const deviceTypeSlice = createSlice({
  name: "deviceType",
  initialState,
  reducers: {
    setDeviceType: (state, action) => {
      state.screenType = action.payload;
    },
  },
});
const deviceTypeReducer = deviceTypeSlice.reducer;

export const { setDeviceType } = deviceTypeSlice.actions;

export default deviceTypeReducer;
