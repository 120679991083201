import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: "English",
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    changeLanguage(state, action) {
      state.language = action.payload;
    },
  },
});

const languageReducer = languageSlice.reducer;

export const { changeLanguage } = languageSlice.actions;

export default languageReducer;
