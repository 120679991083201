import { useEffect, useRef, useState } from "react";
import {
  placesAroundList_en,
  placesAroundList_bm,
  theme_color_black,
} from "../../const";
import { useSelector } from "react-redux";
import { Flex, Image, Text, IconButton } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper";
import useHover from "./../useHover";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

export const PlacesAround = () => {
  const deviceType = useSelector((state) => state.DeviceType.screenType);
  const languageState = useSelector((state) => state.Language.language);
  const [isMobile, setIsMobile] = useState(deviceType === "mobile");
  const [translations, setTranslations] = useState({});
  const [placesAround, setPlacesAround] = useState(placesAroundList_en);
  const [image, setImage] = useState(placesAround[0].place);
  const [description, setDescription] = useState(placesAround[0].description);
  const [activeIndex, setActiveIndex] = useState(0);
  let placesAroundLength = placesAround.length;
  const swiperRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [flexRef, isHovered] = useHover();

  useEffect(() => {
    if (deviceType === "wide") {
      setIsMobile(false);
    }
    if (deviceType === "narrow") {
      setIsMobile(false);
    }
    if (deviceType === "mobile") {
      setIsMobile(true);
    }
  }, [deviceType]);

  useEffect(() => {
    if (languageState === "English") {
      import(`../en.json`)
        .then((res) => {
          setTranslations(res.placesAround);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
      setPlacesAround(placesAroundList_en);
    }
    if (languageState === "Malay") {
      import(`../bm.json`)
        .then((res) => {
          setTranslations(res.placesAround);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
      setPlacesAround(placesAroundList_bm);
    }
  }, [languageState]);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      if (isHovered) {
        swiperRef.current.swiper.autoplay.stop();
      } else {
        swiperRef.current.swiper.autoplay.start();
      }
    }
  }, [isHovered]);

  const LocationBox = ({ location, activeIndex, index }) => {
    return (
      <Flex
        w={"100%"}
        color={activeIndex === index ? "#AF6534" : "black"}
        p={isMobile ? "5px" : "30px"}
        fontSize={isMobile ? "16px" : "23px"}
        bgColor="whites"
        justify={"center"}
        align={"center"}
        textAlign={"center"}
        borderTopWidth={"1px"}
        borderBottomWidth={"1px"}
        borderColor={"#AF6534"}
        margin={"0px"}
        h={"100%"}
      >
        {location}
      </Flex>
    );
  };

  const ImageBox = ({ image, onImageClick, index, description, title }) => {
    return (
      <Flex flexDir={"column"} w={"100%"} h={"100%"} justifyContent={"space-around"}>
        <Flex alignContent={"center"} w={"100%"} h={"100%"} onClick={() => onImageClick(index)}>
          <Image
            src={image}
            w={"100%"}
            h={"100%"}
            fit={"cover"}
            borderRadius={"15px"}
          />
          <Flex
            position="absolute"
            bottom="0"
            left="0"
            width="100%"
            alignItems="end"
            borderRadius={"15px"}
            bgGradient="linear(to-b, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 10%, rgba(0, 0, 0, 0.4) 20%, rgba(0, 0, 0, 0.6) 30%, rgba(0, 0, 0, 0.7) 70%, rgba(0, 0, 0, 0.9) 90%, black 100%)"
            color="white"
            pb="10px"
          >
            <Text px={"10px"} py={"20px"} fontSize={"15px"} textAlign={"center"}>
              {description}
            </Text>
          </Flex>
          <Flex
            position="absolute"
            top="0"
            width="100%"
            alignItems="start"
            justifyContent="center"
            borderRadius={"15px"}
            bgGradient="linear(to-b, rgba(0, 0, 0, 1.0) 0%, rgba(0, 0, 0, 0.8) 30%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0) 100%)"
            color="white"
            pb="10px"
          >
            <Text px={"10px"} py={"20px"} fontSize={"20px"} textAlign={"center"} className="font-link-Ibarra">
              {title}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const handleImageClick = (index) => {
    setActiveIndex(index);
    setIsFullScreen(true);
  };

  return (
    <Flex
      flexDir={"column"}
      justifyContent={"space-around"}
      w={"100%"}
      bgColor={"theme_color_white"}
      p={isMobile ? "5%" : "50px"}
      pt={isMobile ? "15%" : "50px"}
    >
      <Flex
        justifyContent={isMobile ? "center" : "start"}
        w={"100%"}
        align={"center"}
        pl={isMobile ? "0px" : "50px"}
      >
        <Text
          textAlign={isMobile ? "center" : "start"}
          className="font-link-Anton"
          fontSize={isMobile ? "24px" : "60px"}
          color={"#AF6534"}
        >
          {translations.tagline1}
        </Text>
      </Flex>
      <Flex
        justifyContent={isMobile ? "center" : "start"}
        w={"100%"}
        align={"center"}
        pl={isMobile ? "0px" : "50px"}
        pb={isMobile ? "5%" : "0px"}
      >
        <Text
          textAlign={isMobile ? "center" : "start"}
          className="font-link-Anton"
          fontSize={isMobile ? "32px" : "44px"}
          color={theme_color_black}
        >
          {translations.tagline2}
        </Text>
      </Flex>

      {isMobile ?
        <>
          <Flex bgColor={"white"} h={"60vh"}>
            <Swiper
              borderRadius={"30%"}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              slidesPerView={isMobile ? "1" : "4"}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2.5,
              }}
              autoplay={{
                delay: 3000, // 3 seconds
                disableOnInteraction: isMobile ? true : false,
              }}
              pagination={{
                clickable: true,
                type: "bullets",
              }}
              modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
              className="swiper_container"
            >
              {placesAroundList_en.map((place, index) => {
                return (
                  <SwiperSlide key={index}>
                    <ImageBox image={place.mobileImage}
                      description={place.description}
                      title={place.place}
                      onImageClick={() => handleImageClick(index)} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Flex>
          {isFullScreen && (
            <Flex
              className="fullscreen-swiper"
              position="fixed"
              top="0"
              left="0"
              width="100%"
              height="100%"
              zIndex="1000"
              bg="black"
            >
              <Swiper
                initialSlide={activeIndex}
                slidesPerView={1}
                loop={true}
                navigation
                pagination={{ clickable: true }}
                onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
              >
                {placesAround.map((place, index) => (
                  <SwiperSlide
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <Flex
                      width="100%"
                      alignItems="center"
                      justifyContent="center"
                      color="white"
                      pt="10px"
                    >
                      <Text
                        px="10px"
                        py="20px"
                        fontSize="20px"
                        textAlign="center"
                        className="font-link-Ibarra"
                      >
                        {place.place}
                      </Text>
                    </Flex>
                    <Image
                      src={place.mobileImage}
                      maxW="100%"
                      maxH="70vh"
                      fit="cover"
                    />
                    <Flex
                      width="100%"
                      alignItems="center"
                      justifyContent="center"
                      color="white"
                      pb="10px"
                    >
                      <Text
                        px="10px"
                        py="20px"
                        fontSize="15px"
                        textAlign="center"
                      >
                        {place.description}
                      </Text>
                    </Flex>
                  </SwiperSlide>
                ))}
              </Swiper>

              <Flex
                position="absolute"
                top="10px"
                left="0"
                right="0"
                justifyContent="center"
                alignItems="center"
                zIndex="1001">
                <IconButton
                  icon={<CloseIcon />}
                  position="absolute"
                  top="10px"
                  right="10px"
                  zIndex="1001"
                  borderRadius={"full"}
                  color="black"
                  bg="white"
                  onClick={() => setIsFullScreen(false)}
                  aria-label="Close"
                />
                <Text
                  className="font-link-Anton"
                  fontSize={"20px"}
                  color="white"
                  alignItems={"center"}
                  top="15px"
                  position="absolute"
                >
                  {activeIndex + 1}/{placesAround.length}
                </Text>
              </Flex>
            </Flex>
          )}
        </>
        :
        <Flex
          flexDir={"row"}
          justifyContent={"space-around"}
          w={"100%"}
          align={"center"}
          pl={"50px"}
          pr={"50px"}
          pt={"50px"}
        >
          <Flex
            w={"40%"}
            justifyContent={"center"}
            alignContent={"start"}
          >
            <Swiper
              ref={swiperRef}
              direction={"vertical"}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              slidesPerView={"5"}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 0,
                modifier: 0,
              }}
              autoplay={{
                delay: 2000, // 3 seconds
                disableOnInteraction: false,
              }}
              modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
              className="swiper_container"
              style={{
                alignSelf: "end",
                width: "100%",
                maxHeight: "300px",
                overflowY: "auto",
                scrollbarWidth: "none",
                overflow: "hidden",
              }}
              onSlideChange={(swiper) => {
                setImage(placesAround[swiper.realIndex].image);
                setDescription(placesAround[swiper.realIndex].description);
                setActiveIndex(swiper.realIndex);
              }}
            >
              {Array.from(
                { length: placesAroundLength },
                (_, index) => index
              ).map((index) => {
                return (
                  <SwiperSlide
                    key={index}
                    style={{ margin: 0, padding: 0, height: "100%" }}
                  >
                    <LocationBox
                      location={placesAround[index].place}
                      activeIndex={activeIndex}
                      index={index}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Flex>
          <Flex
            w={"55%"}
            h={"420px"}
            position="relative"
            ref={flexRef}
          >
            <Image
              src={image}
              objectFit="cover"
              width="100%"
              height="100%"
              borderRadius={"20px"}
            />
            {isHovered && (
              <Flex
                position="absolute"
                bottom="0"
                left="0"
                width="100%"
                height="60%"
                alignItems="end"
                bgColor="rgba(0, 0, 0, 0)"
                _hover={{
                  bgGradient:
                    "linear(to-b, rgba(0, 0, 0, 0) 2%, rgba(0, 0, 0, 0.3) 20%, rgba(0, 0, 0, 0.7) 70%, black 100%)",
                }}
                color="white"
                pb={"10px"}
              >
                <Text padding={"10px"} fontSize={"15px"} textAlign={"center"}>
                  {description}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      }

      <Flex h={isMobile ? "30px" : "50px"}></Flex>
    </Flex>
  );
};
