import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { thunk } from "redux-thunk";
import deviceTypeReducer from "./landingPage/redux/deviceTypeSlice";
import languageReducer from "./landingPage/redux/languageSlice";
import reviewVisibleReducer from "./landingPage/redux/reviewVisibleSlice";

const reducer = combineReducers({
  Language: languageReducer,
  DeviceType: deviceTypeReducer,
  ReviewVisible: reviewVisibleReducer,
});

const initialState = {};

const store = configureStore({
  reducer,
  initialState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;

export const useAppDispatch = () => useDispatch();
